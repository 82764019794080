<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>About Us</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                   <p>
                      <img src="@/assets/img/org.svg" alt="">
                  </p>                  
              </div>
              
          <div class="sidebar">
              <AboutUsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AboutUsQuickLinks from '../../components/page/AboutUsQuickLinks.vue';
import Footer from "../../components/page/Footer.vue";




export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, AboutUsQuickLinks, Footer },
    setup(){
        const title= ref('Organogram')
        return {title}
    }
    
  
}
</script>

<style scoped>
section .container .page .page-content p img{
    width: 100%;
}

</style>